<!-- eslint-disable -->
<script setup>
import statistiquesExcel from "@/components/ToPDF/Statistiques/excel.vue";
import CardSearch from "@/components/CardSearch.vue";
</script>
<!-- eslint-disable -->
<template>

  <div class="row mb-4">
    <div class="col-md-4 text-end float-end">
      <statistiquesExcel :exportTo="exportTo" />
    </div>
  </div>
  <CardSearch v-if="currentSite" @search="callFunctions" :operation="false" :etat="false" :caisse="true"
    :vendeur="true" />


  <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
         <SectionLoaderView v-if="loadingRequest?.ArticlesRemises" />
        <div class="card-header">
          <h3 class="card-title text-left">Articles Remises</h3>
        </div>
        <div v-if="data.articles_remises.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines v-model:expandedRows="expandedRemisesRows" :value="data.articles_remises"
              dataKey="_id">
              <Column expander style="width: 5rem" />
              <Column field="_id" header="Vendeur"></Column>
              <Column field="TauxVendeur" header="Qte"> </Column>
              <Column field="TotalValeurVendeur" header="CA">
                <template #body="slotProps">
                  {{ slotProps.data.TotalValeurVendeur.toFixed(2) }} €
                </template>
              </Column>
              <template #expansion="slotProps">
                <div class="p-3">
                  <DataTable showGridlines :value="slotProps.data.ArticlesRemises">
                    <Column field="libelle" header="libelle" sortable></Column>
                    <Column field="LibCaisse" header="Caisse" sortable></Column>
                    <Column field="TotalTaux" header="Qte" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.TotalTaux }}
                      </template>
                    </Column>
                    <Column field="TotalValeurLibelle" header="Total" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.TotalValeurLibelle.toFixed(2) }} €
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>

          </div>
        </div>
        <div v-else class="card-body">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <SectionLoaderView v-if="loadingRequest?.ArticlesOfferts" />
        <div class="card-header">
          <h3 class="card-title text-left">Articles Offerts</h3>
        </div>
        <div v-if="data.articles_offerts.length > 0" class="card-body">
          <div class="table-responsive tableClasss">


            <DataTable showGridlines v-model:expandedRows="expandedOffertsRows" :value="data.articles_offerts"
              dataKey="_id">
              <Column expander style="width: 5rem" />
              <Column field="_id" header="Vendeur"></Column>
              <Column field="QuantityVendeur" header="Qte"> </Column>
              <Column field="TotalValeurVendeur" header="CA">
                <template #body="slotProps">
                  {{ slotProps.data.TotalValeurVendeur.toFixed(2) }} €
                </template>
              </Column>
              <template #expansion="slotProps">
                <div class="p-3">
                  <DataTable showGridlines :value="slotProps.data.ArticlesOfferts">
                    <Column field="libelle" header="libelle" sortable></Column>
                    <Column field="LibCaisse" header="Caisse" sortable></Column>
                    <Column field="QuantityLibelle" header="Qte" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.QuantityLibelle }}
                      </template>
                    </Column>
                    <Column field="TotalValeurLibelle" header="Total" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.TotalValeurLibelle.toFixed(2) }} €
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>

          </div>
        </div>
        <div v-else class="card-body">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
         <SectionLoaderView v-if="loadingRequest?.ArticlesAnnules" />
        <div class="card-header">
          <h3 class="card-title text-left">Articles Annulés</h3>
        </div>
        <div v-if="data.articles_annules.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines v-model:expandedRows="expandedAnnulationRows" :value="data.articles_annules"
              dataKey="_id">
              <Column expander style="width: 5rem" />
              <Column field="_id" header="Vendeur"></Column>
              <Column field="QuantityVendeur" header="Qte"> </Column>
              <Column field="TotalValeurVendeur" header="CA">
                <template #body="slotProps">
                  {{ slotProps.data.TotalValeurVendeur.toFixed(2) }} €
                </template>
              </Column>
              <template #expansion="slotProps">
                <div class="p-3">
                  <DataTable showGridlines :value="slotProps.data.ArticlesAnnules">
                    <Column field="libelle" header="libelle" sortable></Column>
                    <Column field="LibCaisse" header="Caisse" sortable></Column>
                    <Column field="QuantityLibelle" header="Qte" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.QuantityLibelle }}
                      </template>
                    </Column>
                    <Column field="TotalValeurLibelle" header="Total" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.TotalValeurLibelle.toFixed(2) }} €
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>

          </div>

        </div>
        <div v-else class="card-body">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { VueDatePicker },
  watch: {
    'datePicker.date.range'(newDate, oldDate) {
      this.callFunctions();
    },
  },
  data() {
    return {
      exportTo: {

        articles_offerts: {
          columns: [],
          data: [],
          fileName: "",
        },
        articles_remises: {
          columns: [],
          data: [],
          fileName: "",
        },
        articles_annules: {
          columns: [],
          data: [],
          fileName: "",
        },
      },
      data: {

        articles_offerts: [],
        articles_remises: [],
        articles_annules: [],
      },

      searchs: {
        vendeurs: ["vendeur"],
      },
      // SelectedLibCaisses: [],
      // SelectedLibVendeurs: [],
      interval: null,
      expandedAnnulationRows: [],
      expandedRemisesRows: [],
      expandedOffertsRows: [],
    };
  },

  methods: {

    ...mapMutations(["setTicketID"]),
    ...mapActions([

      "ArticlesOfferts",
      "ArticlesRemises",
      "ArticelsAnnules",
    ]),


    async getArticlesofferts(from, to) {
      this.data.articles_offerts = await this.ArticlesOfferts({
        url: "articles-offerts",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        //balisename: "OFFERT",
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      this.exportTo.articles_offerts.data = [];
      this.exportTo.articles_offerts.data = this.data.articles_offerts;
    },
    async getArticlesRemises(from, to) {
      this.data.articles_remises = await this.ArticlesRemises({
        url: "articles-remises",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      this.exportTo.articles_remises.data = [];
      this.exportTo.articles_remises.data = this.data.articles_remises;
    },
    async getArticelsAnnules(from, to) {
      this.data.articles_annules = await this.ArticelsAnnules({
        url: "articles-annules",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['ANNULATION'], LibKey: 'Operation' },
        ]
      });
      this.exportTo.articles_annules.data = [];
      this.exportTo.articles_annules.data = this.data.articles_annules;
    },
    callFunctions() {
      if (this.currentSite?._id) {
        let range = this.datePicker.defaultDates.type ? this.datePicker.defaultDates.type : this.datePicker.date.from

        this.getArticlesofferts(range, this.datePicker.date.to);
        this.getArticlesRemises(range, this.datePicker.date.to);
        this.getArticelsAnnules(range, this.datePicker.date.to);
      }
    },
    ShowHide(index, iconshow, iconhide, libelle) {
      var ele_arr_down = document.getElementById(iconshow + index);
      var ele_arr_right = document.getElementById(iconhide + index);
      var ele_sub_table = document.getElementById(libelle + index);
      ele_sub_table.classList.toggle("show");
      ele_arr_down.classList.toggle("hide");
      ele_arr_right.classList.toggle("hide");
    },

    priceFormat(value) {
      return "€ " + value;
    },

  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
    ...mapState(["ticket_id", "datePicker", "globalSearch", "loadingRequest"]),
  },
};
</script>
<!-- eslint-disable -->
<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}
</style>
