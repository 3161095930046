<!-- eslint-disable -->

<script setup>
import { ref } from 'vue'
import SearchInput from './SearchInput.vue'
import WeatherCard from './WeatherCard.vue'

defineProps({
    EmetteurVille: String
})

const places = ref([])

const addPlace = (data) => {
    places.value.push(data)
}

const deletePlace = (name) => {
    if (confirm('Are you sure')) {
        places.value = places.value.filter((p) => p.location.name !== name)
    }
}
</script>
<!-- eslint-disable -->
<template>
    <div class="w-100">
        <!-- Date -->
        <!-- <div class="text-center mb-6">
            {{
                new Date().toLocaleDateString('en-us', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })
            }}
        </div> -->

        <!-- Search -->
        <div>

            <SearchInput @place-data="addPlace" :EmetteurVille="EmetteurVille" />
        </div>

        <!-- Weather cards -->
        <div class="grid  ">
            <div v-for="(place, idx) in places" :key="idx">
                <WeatherCard :place="place" @delete-place="deletePlace" />
            </div>
        </div>
    </div>
</template>