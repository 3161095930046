<!-- eslint-disable -->
<template>
  <div>
    <div>
      <span class="generate-to-file pdf" @click="generatePdf"><i class="far fa-file-pdf"></i></span>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
//import Vue3Html2pdf from "vue3-html2pdf";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  props: ["dateFrom", "dateTo", "vendeur", "exportTo"],
  components: {
    //Vue3Html2pdf,
  },
  data() {
    return {
      clicked: false,
    };
  },
  methods: {
    convertToArraysAndFloats(array) {
      return array.map((item) => {
        return Object.values(item).map((value) => {
          if (!isNaN(value) && value !== null && value !== "") {
            return parseFloat(value).toFixed(2); // Convert and format numeric values
          }
          return value; // Keep non-numeric values unchanged
        });
      });
    },
    async generatePdf() {
      const updatedDataFooter = this.convertToArraysAndFloats([this.exportTo?.totalObject]);
      const updatedDataBody = this.convertToArraysAndFloats(this.exportTo?.finaleResults);

      var doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 20,
      });

      let startY = 15;
      console.log("this.exportTo.site")
      console.log(this.exportTo.site)
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      // Draw the line
      // startY += 2;
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startY, pageWidth - 15, startY);
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startY + 0.3, pageWidth - 15, startY + 0.3);

      let X_POS_CENTER = pageWidth / 2;
      if (this.exportTo.site.LibSite) {
        startY += 6;
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(10);
        doc.text(this.exportTo.site.LibSite, X_POS_CENTER, startY, { align: "center" });
      }


      if (this.exportTo.site.EmetteurVille) {
        startY += 5;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurVille, X_POS_CENTER, startY, {
          align: "center",
        });

      }
      if (this.exportTo?.site?.EmetteurAdresse) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurAdresse, X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurCodePostal) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurCodePostal.toString(), X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurVille) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurVille.toString(), X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurPays) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurPays.toString(), X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurTelephone) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurTelephone.toString(), X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurCodeNAF) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(
          this.exportTo.site.EmetteurCodeNAF.toString() +
          " " +
          this.exportTo.site.EmetteurNumTVA.toString(),
          X_POS_CENTER,
          startY,
          { align: "center" }
        );
      }

      if (this.exportTo?.site?.EmetteurSiret) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(
          "SIRET " + this.exportTo.site.EmetteurSiret.toString(),
          X_POS_CENTER,
          startY,
          { align: "center" }
        );
      }
      startY += 8;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(13);
      var text = "Journalier TVAs";
      doc.text(text, pageWidth / 2, startY, { align: "center" });

      // Draw the line
      startY += 2;
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startY, pageWidth - 15, startY);
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startY + 0.3, pageWidth - 15, startY + 0.3);

      // Draw line left
      var lineLength = startY;
      var startYline = 15;
      var startXline = startY;
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startYline, 15, lineLength);
      doc.setDrawColor(0, 0, 0);
      doc.line(15 + 0.3, startYline, 15 + 0.3, lineLength);
      // end line left
      // Draw line right
      doc.setDrawColor(0, 0, 0);
      doc.line(pageWidth - 15, startYline, pageWidth - 15, lineLength);
      doc.setDrawColor(0, 0, 0);
      doc.line(pageWidth - 14.7, startYline, pageWidth - 14.7, lineLength);
      // end line right
      // startY += 4;
      // doc.setFont("helvetica", "normal", "bold");
      // doc.setFontSize(11);
      // var pageWidth =
      //   doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      // var text = "Journalier TVAs";
      // doc.text(text, pageWidth / 2, startY, { align: "center" });



      startY += 15;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE DE DEBUT", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.dateFrom, 44, startY, "left");

      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE DE FIN", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.dateTo, 44, startY, "left");

      // if (this.vendeur.length == 1 && this.vendeur[0] != 'vendeur') {
      //     startY += 6
      //     doc.setFont("helvetica", 'normal', "bold");
      //     doc.setFontSize(9)
      //     doc.text("VENDEUR", 15, startY, 'left');
      //     doc.setFont("helvetica", 'normal', "normal");
      //     doc.setFontSize(9)
      //     doc.text(' : ' + this.vendeur[0], 44, startY, 'left');
      // }

      startY += 15;
      autoTable(doc, {
        margin: { top: 30 },
        startY: startY,
        pageBreak: "auto",
        theme: "striped",
        headStyles: {
          fillColor: [17, 69, 108],
          textColor: [255, 255, 255],
          cellPadding: 4,
        },
        bodyStyles: { cellPadding: 3 },
        head: [Object.keys(this.exportTo?.finaleResults[0])],
        body: updatedDataBody,
        foot: updatedDataFooter,
      });

      doc.save("Journalier TVAs");
    },
  },
  mounted() { },
};
</script>
