<!-- eslint-disable -->
<script setup>
import statistiquesPDF from "@/components/ToPDF/Statistiques/IndexPdf.vue";
import statistiquesExcel from "@/components/ToPDF/Statistiques/excel.vue";
import CardSearch from "@/components/CardSearch.vue";
</script>
<!-- eslint-disable -->
<template>
  <div class="row mb-4">
    <div class="col-md-4 text-end float-end">
      <statistiquesExcel :exportTo="exportTo" />
    </div>
  </div>
  <CardSearch v-if="currentSite" @search="callFunctions" :operation="false" :etat="false" :caisse="true"
    :vendeur="true" />

  <div class="row">

    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
          <SectionLoaderView v-if="loadingRequest?.BoardRevenuStatistiques" />
        <div class="card-header">
          <h3 class="card-title text-left">
            Revenu par statistiques
            <div class="float-end">
              <statistiquesPDF :exportTo="exportTo.revenu_statistques" :vendeur="searchs.vendeurs"
                :dateFrom="datePicker.date.from" :dateTo="datePicker.date.to" />
            </div>
          </h3>
        </div>
        <div v-if="data.revenu_statistques.length > 0" class="card-body">
          <div class="table-responsive  ">

            <DataTable showGridlines stripedRows :value="data.revenu_statistques">
              <Column field="_id.LibVendeur" header="Vendeur" sortable></Column>
              <Column field="Family" header="Libelle" sortable> </Column>
              <Column field="QteTotal" header="Quantité" sortable> </Column>
              <Column field="TotalValeur" header="Montant (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalValeur?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>

</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { VueDatePicker, statistiquesPDF },
  watch: {
    'datePicker.date.range'(newDate, oldDate) {

      this.callFunctions();
    },
  },
  data() {
    return {
      exportTo: {
        vendeurs: {
          columns: [],
          data: [],
          fileName: "",
        },

        statistiques: {
          columns: [],
          data: [],
          fileName: "",
        },

        revenu_statistques: {
          columns: [],
          data: [],
          fileName: "",
        },

      },
      data: {

        statistiques: [],

        revenu_statistques: [],
      },
      pagination: {
        page: 0,
        limit: 4,
        skip: 0,
        per_page: 10,
        data: [],
      },
      searchs: {
        vendeurs: ["vendeur"],
      },
      interval: null,
    };
  },

  methods: {

    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "BoardRevenuStatistiques",
    ]),


    async getRevenuStatistiques(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0;
      this.data.revenu_statistques = await this.BoardRevenuStatistiques({
        url: "board/revenu-statistiques",
        page: page,
        limit: limit,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        qte: qte,
        groupbycaisse: "none",
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      this.exportTo.revenu_statistques.data = [];
      this.exportTo.revenu_statistques.data = this.data.revenu_statistques;
      let file_vendeur = "";
      if (
        this.searchs.vendeurs.length == 1 &&
        this.searchs.vendeurs[0] != "vendeur"
      ) {
        file_vendeur = "-" + this.searchs.vendeurs[0];
      }
      this.exportTo.revenu_statistques.fileName =
        "revenu-statistques" + file_vendeur + "_" + from + "_" + to;
      if (this.data.revenu_statistques.length > 0) {
        this.exportTo.revenu_statistques.columns = [];
        if (file_vendeur == "") {
          this.exportTo.revenu_statistques.columns.push("Vendeur");
        }
        this.exportTo.revenu_statistques.columns.push("Libelle");
        this.exportTo.revenu_statistques.columns.push("Quantité");
        this.exportTo.revenu_statistques.columns.push("Montant");
      }
    },

    callFunctions() {
      if (this.currentSite?._id) {
        let range = this.datePicker.defaultDates.type ? this.datePicker.defaultDates.type : this.datePicker.date.from

        this.getRevenuStatistiques(range, this.datePicker.date.to);
      }
    },
    ShowHide(index, iconshow, iconhide, libelle) {
      var ele_arr_down = document.getElementById(iconshow + index);
      var ele_arr_right = document.getElementById(iconhide + index);
      var ele_sub_table = document.getElementById(libelle + index);
      ele_sub_table.classList.toggle("show");
      ele_arr_down.classList.toggle("hide");
      ele_arr_right.classList.toggle("hide");
    },

    priceFormat(value) {
      return "€ " + value;
    },

  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
    ...mapState(["ticket_id", "datePicker", "globalSearch", "loadingRequest"]),
  },
};
</script>
<!-- eslint-disable -->
<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}
</style>
