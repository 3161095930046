<!-- eslint-disable -->
<script setup>
import journaliesPayements from "@/components/ToPDF/reportFamille/journaliesPayements.vue";

import CardSearch from "@/components/CardSearch.vue";
import { ref } from 'vue';
</script>
<!-- eslint-disable -->
<template>
  <!-- <ButtonDates /> -->
  <CardSearch v-if="currentSite" @search="callFunctions" :operation="false" :etat="false" :caisse="true"
    :vendeur="true" />


  <div class="row">
    <div class="col-md-12">



      <div v-if="journalier_payments" class="row">
        <div class=" col-md-12 grid-margin stretch-card">
          <div class="card position-relative">
            <SectionLoaderView v-if="loadingRequest?.JournalierPayments" />
            <div class="card-header">
              <h3 class="card-title text-left">
                journalier payments
                <div class="float-end d-flex">
                  <journaliesPayements :exportTo="journalier_payments" :dateFrom="datePicker.date.from"
                    :dateTo="datePicker.date.to" />
                </div>
              </h3>
            </div>
            <div class="card-body">
              <DataTable v-if="journalier_payments['finaleResults']?.length > 0" showGridlines
                :value="journalier_payments['finaleResults']">
                <Column v-for="(value, key) in journalier_payments['finaleResults'][0]" :key="key" :field="key"
                  :header="key"
                  :footer="(key != 'LibSite' && key != 'Jour') ? valToFixedTo(journalier_payments.totalObject[key]) + ' €' : journalier_payments.totalObject[key]"
                  sortable>
                  <template #body="slotProps" :key=key>
                    <span v-if="key != 'LibSite' && key != 'Jour'">
                      {{ valToFixedTo(slotProps.data[key]) }} € <!-- Call valToFixedTo method -->
                    </span>
                    <span v-else>
                      {{ (slotProps.data[key]) }}
                    </span>
                  </template>
                </Column>

              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DoughnutChart from "@/components/chartVue/ChartDoughnut.vue";
import { Division } from "@/Utils/globale.js";

export default {
  components: { DoughnutChart },
  watch: {
    'datePicker.date.range'(newDate, oldDate) {

      this.callFunctions();
    }
  },
  data() {
    return {
      journalier_payments: [],



    };
  },
  mounted() {



  },
  methods: {
    ...mapMutations(["setTicketID"]),
    ...mapActions(["JournalierPayments"]),
    Division,

    async getReportJournalierPayments(from, to) {
      this.journalier_payments = await this.JournalierPayments({
        url: "report/daily-payments",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      this.journalier_payments.site = this.currentSite
    },

    callFunctions() {
      if (this.currentSite?._id) {
        let range = this.datePicker.defaultDates.type ? this.datePicker.defaultDates.type : this.datePicker.date.from


        this.getReportJournalierPayments(range, this.datePicker.date.to);
      }
    },
    valToFixedTo(value) {
      if (typeof value === 'number') {
        return value.toFixed(2);
      }
      return parseFloat(value).toFixed(2); // or handle other types if necessary
    }
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
    ...mapState(["ticket_id", "datePicker", "globalSearch", "loadingRequest"]),


  },
};
</script>
<!-- eslint-disable -->
<style></style>
