<!-- eslint-disable -->
<script setup>
import statistiquesPDF from "@/components/ToPDF/Statistiques/IndexPdf.vue";
import statistiquesExcel from "@/components/ToPDF/Statistiques/excel.vue";
import meilleursVentesPDF from "@/components/ToPDF/meilleursVentes/IndexPdf.vue";
import ventesParVendeurPDF from "@/components/ToPDF/ventesParVendeur/IndexPdf.vue";
import CardSearch from "@/components/CardSearch.vue";
</script>
<!-- eslint-disable -->
<template>
  <div class="row mb-4">
    <div class="col-md-4 text-end float-end">
      <statistiquesExcel :exportTo="exportTo" />
    </div>
  </div>
  <CardSearch v-if="currentSite" @search="callFunctions" :operation="false" :etat="false" :caisse="true"
    :vendeur="true" />

  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card position-relative">
         <SectionLoaderView v-if="loadingRequest?.BoardMeilleursVents" />
        <div class="card-header">
          <h3 class="card-title text-left">
            Meilleures 10 ventes
            <div v-if="data.meilleurventes.data && data.meilleurventes.data.length > 0
            " class="float-end">
              <meilleursVentesPDF :exportTo="exportTo.meilleurventes" :vendeur="searchs.vendeurs"
                :dateFrom="datePicker.date.from" :dateTo="datePicker.date.to" />
            </div>
          </h3>
        </div>
        <div v-if="data.meilleurventes.data && data.meilleurventes.data.length > 0" class="card-body">
          <div class="table-responsive  ">

            <DataTable showGridlines stripedRows :value="data.meilleurventes.data">
              <Column field="LibVendeur" header="Vendeur" sortable></Column>
              <Column field="Libelle" header="Article" sortable> </Column>
              <Column field="QteTotal" header="Quantité" sortable> </Column>
              <Column field="TotalTTC" header="Montant (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps.data.TotalTTC.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
    <!-- <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">
            Revenu par statistiques
            <div class="float-end">
              <statistiquesPDF :exportTo="exportTo.revenu_statistques" :vendeur="searchs.vendeurs"
                :dateFrom="datePicker.date.from" :dateTo="datePicker.date.to" />
            </div>
          </h3>
        </div>
        <div v-if="data.revenu_statistques.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines stripedRows :value="data.revenu_statistques">
              <Column field="_id.LibVendeur" header="Vendeur" sortable></Column>
              <Column field="Family" header="Libelle" sortable> </Column>
              <Column field="QteTotal" header="Quantité" sortable> </Column>
              <Column field="TotalValeur" header="Montant (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalValeur?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div> -->
  </div>
  <!--<div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">
            Ventes par vendeur
            <div v-if="data.ventes_par_vendeur.length > 0" class="float-end">
              <ventesParVendeurPDF :exportTo="exportTo.ventes_par_vendeur" :vendeur="searchs.vendeurs"
                :dateFrom="datePicker.date.from" :dateTo="datePicker.date.to" />
            </div>
          </h3>
        </div>
        <div v-if="data.ventes_par_vendeur.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines stripedRows :value="data.ventes_par_vendeur">
              <Column field="LibVendeur" header="Vendeur" sortable></Column>
              <Column field="Libelle" header="Article" sortable> </Column>
              <Column field="QteTotal" header="Quantité" sortable> </Column>
              <Column field="TotalTTC" header="Montant (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalTTC?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>
   <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">Articles Remises</h3>
        </div>
        <div v-if="data.articles_remises.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines v-model:expandedRows="expandedRemisesRows" :value="data.articles_remises"
              dataKey="_id">
              <Column expander style="width: 5rem" />
              <Column field="_id" header="Vendeur"></Column>
              <Column field="TauxVendeur" header="Qte"> </Column>
              <Column field="TotalValeurVendeur" header="CA">
                <template #body="slotProps">
                  {{ slotProps.data.TotalValeurVendeur.toFixed(2) }} €
                </template>
              </Column>
              <template #expansion="slotProps">
                <div class="p-3">
                  <DataTable showGridlines :value="slotProps.data.ArticlesRemises">
                    <Column field="libelle" header="libelle" sortable></Column>
                    <Column field="LibCaisse" header="Caisse" sortable></Column>
                    <Column field="TotalTaux" header="Qte" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.TotalTaux }}
                      </template>
                    </Column>
                    <Column field="TotalValeurLibelle" header="Total" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.TotalValeurLibelle.toFixed(2) }} €
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>

          </div>
        </div>
        <div v-else class="card-body">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">Articles Offerts</h3>
        </div>
        <div v-if="data.articles_offerts.length > 0" class="card-body">
          <div class="table-responsive tableClasss">


            <DataTable showGridlines v-model:expandedRows="expandedOffertsRows" :value="data.articles_offerts"
              dataKey="_id">
              <Column expander style="width: 5rem" />
              <Column field="_id" header="Vendeur"></Column>
              <Column field="QuantityVendeur" header="Qte"> </Column>
              <Column field="TotalValeurVendeur" header="CA">
                <template #body="slotProps">
                  {{ slotProps.data.TotalValeurVendeur.toFixed(2) }} €
                </template>
              </Column>
              <template #expansion="slotProps">
                <div class="p-3">
                  <DataTable showGridlines :value="slotProps.data.ArticlesOfferts">
                    <Column field="libelle" header="libelle" sortable></Column>
                    <Column field="LibCaisse" header="Caisse" sortable></Column>
                    <Column field="QuantityLibelle" header="Qte" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.QuantityLibelle }}
                      </template>
                    </Column>
                    <Column field="TotalValeurLibelle" header="Total" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.TotalValeurLibelle.toFixed(2) }} €
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>

          </div>
        </div>
        <div v-else class="card-body">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">Articles Annulés</h3>
        </div>
        <div v-if="data.articles_annules.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines v-model:expandedRows="expandedAnnulationRows" :value="data.articles_annules"
              dataKey="_id">
              <Column expander style="width: 5rem" />
              <Column field="_id" header="Vendeur"></Column>
              <Column field="QuantityVendeur" header="Qte"> </Column>
              <Column field="TotalValeurVendeur" header="CA">
                <template #body="slotProps">
                  {{ slotProps.data.TotalValeurVendeur.toFixed(2) }} €
                </template>
              </Column>
              <template #expansion="slotProps">
                <div class="p-3">
                  <DataTable showGridlines :value="slotProps.data.ArticlesAnnules">
                    <Column field="libelle" header="libelle" sortable></Column>
                    <Column field="LibCaisse" header="Caisse" sortable></Column>
                    <Column field="QuantityLibelle" header="Qte" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.QuantityLibelle }}
                      </template>
                    </Column>
                    <Column field="TotalValeurLibelle" header="Total" sortable>
                      <template #body="slotProps">
                        {{ slotProps?.data?.TotalValeurLibelle.toFixed(2) }} €
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>

          </div>

        </div>
        <div v-else class="card-body">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>-->
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { VueDatePicker, statistiquesPDF, meilleursVentesPDF, ventesParVendeurPDF },
  watch: {
    'datePicker.date.range'(newDate, oldDate) {

      this.callFunctions();
    },
  },
  data() {
    return {
      exportTo: {
        vendeurs: {
          columns: [],
          data: [],
          fileName: "",
        },
        meilleurventes: {
          columns: [],
          data: [],
          fileName: "",
        },
        statistiques: {
          columns: [],
          data: [],
          fileName: "",
        },
        ventes_par_vendeur: {
          columns: [],
          data: [],
          fileName: "",
        },
        revenu_statistques: {
          columns: [],
          data: [],
          fileName: "",
        },
        /*articles_offerts: {
          columns: [],
          data: [],
          fileName: "",
        },
        articles_remises: {
          columns: [],
          data: [],
          fileName: "",
        },
        articles_annules: {
          columns: [],
          data: [],
          fileName: "",
        },*/
      },
      data: {
        meilleurventes: [],
        statistiques: [],
        ventes_par_vendeur: [],
        revenu_statistques: [],
        // articles_offerts: [],
        // articles_remises: [],
        // articles_annules: [],
      },
      pagination: {
        page: 0,
        limit: 4,
        skip: 0,
        per_page: 10,
        data: [],
      },
      searchs: {
        vendeurs: ["vendeur"],
      },
      interval: null,
      // expandedAnnulationRows: [],
      // expandedRemisesRows: [],
      // expandedOffertsRows: [],
    };
  },

  methods: {

    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "BoardMeilleursVents",
      // "BoardRevenuStatistiques",
      // "ArticlesoffertsOuRemises",
      // "ArticlesOfferts",
      // "ArticlesRemises",
      // "ArticelsAnnules",
    ]),


    async getBoardMeilleursVents(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 10;
      this.data.meilleurventes = await this.BoardMeilleursVents({
        url: "board/meilleursvents",
        page: page,
        limit: limit,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
      this.exportTo.meilleurventes.data = [];
      this.exportTo.meilleurventes.data = this.data.meilleurventes.data;
      let file_vendeur = "";
      if (
        this.searchs.vendeurs.length == 1 &&
        this.searchs.vendeurs[0] != "vendeur"
      ) {
        file_vendeur = "-" + this.searchs.vendeurs[0];
      }
      this.exportTo.meilleurventes.fileName =
        "ventes-par-vendeur" + file_vendeur + "_" + from + "_" + to;
      if (this.data.meilleurventes.data.length > 0) {
        this.exportTo.meilleurventes.columns = [];
        if (file_vendeur == "") {
          this.exportTo.meilleurventes.columns.push("Vendeur");
        }
        this.exportTo.meilleurventes.columns.push("Article");
        this.exportTo.meilleurventes.columns.push("Quantité");
        this.exportTo.meilleurventes.columns.push("Montant");
      }
    },
    /*async getBoardArticlesVenduParVendeur(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0; // 0 to get all rows
      this.data.ventes_par_vendeur = await this.BoardMeilleursVents({
        url: "board/meilleursvents",
        page: page,
        limit: limit,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
      this.exportTo.ventes_par_vendeur.data = [];
      this.exportTo.ventes_par_vendeur.data = this.data.ventes_par_vendeur;
      let file_vendeur = "";
      if (
        this.searchs.vendeurs.length == 1 &&
        this.searchs.vendeurs[0] != "vendeur"
      ) {
        file_vendeur = "-" + this.searchs.vendeurs[0];
      }
      this.exportTo.ventes_par_vendeur.fileName =
        "ventes-par-vendeur" + file_vendeur + "_" + from + "_" + to;
      if (this.data.ventes_par_vendeur.length > 0) {
        this.exportTo.ventes_par_vendeur.columns = [];
        if (file_vendeur == "") {
          this.exportTo.ventes_par_vendeur.columns.push("Vendeur");
        }
        this.exportTo.ventes_par_vendeur.columns.push("Article");
        this.exportTo.ventes_par_vendeur.columns.push("Quantité");
        this.exportTo.ventes_par_vendeur.columns.push("Montant");
      }
    },
    async getRevenuStatistiques(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0;
      this.data.revenu_statistques = await this.BoardRevenuStatistiques({
        url: "board/revenu-statistiques",
        page: page,
        limit: limit,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        qte: qte,
        groupbycaisse: "none",
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      this.exportTo.revenu_statistques.data = [];
      this.exportTo.revenu_statistques.data = this.data.revenu_statistques;
      let file_vendeur = "";
      if (
        this.searchs.vendeurs.length == 1 &&
        this.searchs.vendeurs[0] != "vendeur"
      ) {
        file_vendeur = "-" + this.searchs.vendeurs[0];
      }
      this.exportTo.revenu_statistques.fileName =
        "revenu-statistques" + file_vendeur + "_" + from + "_" + to;
      if (this.data.revenu_statistques.length > 0) {
        this.exportTo.revenu_statistques.columns = [];
        if (file_vendeur == "") {
          this.exportTo.revenu_statistques.columns.push("Vendeur");
        }
        this.exportTo.revenu_statistques.columns.push("Libelle");
        this.exportTo.revenu_statistques.columns.push("Quantité");
        this.exportTo.revenu_statistques.columns.push("Montant");
      }
    },*/
    /* async getArticlesofferts(from, to) {
       this.data.articles_offerts = await this.ArticlesOfferts({
         url: "articles-offerts",
         page: 1,
         limit: 0,
         range: this.datePicker.date.range,
         vendeur: "vendeur",
         site: this.currentSite?._id,
         //balisename: "OFFERT",
         WhereLibInArray: [
           { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
           { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
           { LibValeur: null, LibKey: 'Operation' },
         ]
       });
       this.exportTo.articles_offerts.data = [];
       this.exportTo.articles_offerts.data = this.data.articles_offerts;
     },
     async getArticlesRemises(from, to) {
       this.data.articles_remises = await this.ArticlesRemises({
         url: "articles-remises",
         page: 1,
         limit: 0,
         range: this.datePicker.date.range,
         vendeur: "vendeur",
         site: this.currentSite?._id,
         WhereLibInArray: [
           { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
           { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
           { LibValeur: null, LibKey: 'Operation' },
         ]
       });
       this.exportTo.articles_remises.data = [];
       this.exportTo.articles_remises.data = this.data.articles_remises;
     },
     async getArticelsAnnules(from, to) {
       this.data.articles_annules = await this.ArticelsAnnules({
         url: "articles-annules",
         page: 1,
         limit: 0,
         range: this.datePicker.date.range,
         vendeur: "vendeur",
         site: this.currentSite?._id,
         WhereLibInArray: [
           { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
           { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
           { LibValeur: ['ANNULATION'], LibKey: 'Operation' },
         ]
       });
       this.exportTo.articles_annules.data = [];
       this.exportTo.articles_annules.data = this.data.articles_annules;
     },*/
    callFunctions() {
      if (this.currentSite?._id) {
        let range = this.datePicker.defaultDates.type ? this.datePicker.defaultDates.type : this.datePicker.date.from
        this.getBoardMeilleursVents(range, this.datePicker.date.to);
        // this.getBoardArticlesVenduParVendeur(range, this.datePicker.date.to);
        // this.getRevenuStatistiques(range, this.datePicker.date.to);
        // this.getArticlesofferts(range, this.datePicker.date.to);
        // this.getArticlesRemises(range, this.datePicker.date.to);
        // this.getArticelsAnnules(range, this.datePicker.date.to);
      }
    },
    ShowHide(index, iconshow, iconhide, libelle) {
      var ele_arr_down = document.getElementById(iconshow + index);
      var ele_arr_right = document.getElementById(iconhide + index);
      var ele_sub_table = document.getElementById(libelle + index);
      ele_sub_table.classList.toggle("show");
      ele_arr_down.classList.toggle("hide");
      ele_arr_right.classList.toggle("hide");
    },

    priceFormat(value) {
      return "€ " + value;
    },

  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
    ...mapState(["ticket_id", "datePicker", "globalSearch", "loadingRequest"]),
  },
};
</script>
<!-- eslint-disable -->
<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}
</style>
