<!-- eslint-disable -->
<template>
  <footer
    class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
    <a class=" bottom-0 badge-version" href="/">
      <img src="../../public/assets/img/logo_dark.png" class="  logo d-block" alt="logo" />
      <span class="cloud-version">
        {{ 'V' + appVersion }}
      </span>
    </a>
    <p class="text-muted mb-1 mb-md-0">Copyright © {{ new Date().getFullYear() }} <a href="https://guestwaiter.com/"
        target="_blank">Guestwaiter</a>.
    </p>
  </footer>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "FooTer",
  computed: {
    ...mapState({ appVersion: 'appVersion' }),


  },
};
</script>
