<!-- eslint-disable -->
<script setup>

import Paginationcomt from "@/components/CompPagination.vue";
//import ButtonDates from "@/components/ButtonDates.vue";
import CardSearch from "@/components/CardSearch.vue";
</script>
<!-- eslint-disable -->
<!-- eslint-disable -->
<!-- eslint-disable -->
<template>
  <div class=" " v-if="show">
    <div class="modal  fade" id="generatePDF" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">

        <div class="modal-content">
          <div class="modal-header">
            <div class="d-block mb-2"> <strong>Exporter ou envoyer par mail</strong> </div>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <form class="forms-sample" method="POST" enctype="multipart/form-data" @submit.prevent="generateFile">
            <div v-if="exportTo?.tickets" class="modal-body">

              <div v-if="exportTo.tickets[0]?.data && type == null" class="row">
                <div class="col-md-12 text-center">
                  <b>
                    Choix du type d'exportation
                  </b>
                </div>
                <div class="col-md-6 text-center">
                  <button @click="typeChoosen('with-details')" type="submit" class="btn btn-primary mb-3">
                    Avec détail des achats </button>
                  <div class="text-center">
                    <img src="../../../public/assets/img/avec-details.png" class="example-file">
                  </div>
                </div>
                <div class="col-md-6 text-center">
                  <button @click="typeChoosen('without-details')" type="submit" class="btn btn-primary mb-3"> Sans
                    détail des achats </button>
                  <div class="text-center">
                    <img src="../../../public/assets/img/sans-details.png" class="example-file">
                  </div>
                </div>
              </div>
              <div v-if="!isMail && (type == 'with-details' || type == 'without-details')" class="row">
                <div class="col-md-12 text-center mb-3">
                  <strong> Le type choisi :
                  </strong>
                  <span v-if="type == 'with-details'">
                    Avec détail des achats
                  </span>
                  <span v-else-if="type == 'without-details'">
                    Sans détail des achats
                  </span>

                </div>
                <div class="col-md-6 text-center">
                  <button @click="generatePdfFile()" type="submit" class="btn btn-primary mb-3">
                    Télécharger le PDF </button>

                </div>
                <div class="col-md-6 text-center">
                  <button @click="typeChoosen('recipients')" type="submit" class="btn btn-primary mb-3">
                    Envoyer par e-mail </button>
                </div>
              </div>
              <div v-if="isMail">
                <div class="row">
                  <div class="col-md-12 mb-4">
                    <p>
                      <strong> Le type choisi :
                      </strong>
                      <span v-if="type == 'with-details'">
                        Avec détail des achats
                      </span>
                      <span v-else-if="type == 'without-details'">
                        Sans détail des achats
                      </span>
                    </p>
                  </div>
                  <div class="col-md-12 mb-4">

                    <p>
                      <b>
                        Ajoutez l'email de vos destinataires
                      </b>
                    </p>
                  </div>
                </div>
                <div v-for="(item, index) in listDestinataires" :key="index" class="row mt-2 ">
                  <div class="col-md-12">
                    <label for="description" class="form-label">Destinataire {{ index + 1 }} </label>
                  </div>
                  <div :class="[index == 0 ? 'col-md-12' : 'col-md-10']">
                    <input :readonly="index == 0 ? true : false" class="form-control" type="email"
                      v-model="listDestinataires[index].Email" required placeholder="Destinataire">
                  </div>
                  <div v-if="index != 0" class="col-md-2">
                    <button type="button" class="btn btn-sm btn-danger w-100 " @click="removeItem(index)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <button class="btn btn-secondary" type="button" @click="addItem">

                      Ajouter un destinataire
                    </button>
                  </div>
                  <div class="col-md-6 text-end">
                    <button class="btn btn-primary text-end" type="button" @click="generateFileToMail">
                      Envoyer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button v-if="type" type="button" class="btn btn-warning" @click="typeChoosen(null)">Retour</button>
              <button type="button" class="btn btn-secondary" @click="closeModal">Annuler</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card ">
           <SectionLoaderView v-if="loadingRequest?.searchContacts " />
          <div class="card-header">
            <h3 class="card-title text-left">
              <div class="noble-ui-logo d-block mb-2">
                Information<span>&nbsp; du compte</span>
              </div>
            </h3>
          </div>
          <div v-if="contacts.length > 0" class="card-body">
            <div v-for="(contact, indexCompte) in contacts  " :key="`report-${indexCompte}`" class="row">
              <div class="col-md-6 mb-2">
                <b>Nom :</b> {{ contact.Titre + ' ' + contact.Nom }}
              </div>
              <div class="col-md-6 mb-2">
                <b>Pays :</b>
                <span v-if="contact?.PAYS">
                  {{ contact?.PAYS + ' ' }}
                </span>
                <span v-if="contact?.SITUATION?.VILLE">
                  {{ contact?.SITUATION?.VILLE + ' ' }}
                </span>
                <span v-if="contact?.SITUATION?.CODEPOSTAL">
                  {{ contact?.SITUATION?.CODEPOSTAL + ' ' }}
                </span>
                <span v-if="contact?.SITUATION?.TYPE">
                  {{ contact?.SITUATION?.TYPE + ' ' }}
                </span>
              </div>
              <div class="col-md-6 mb-2">
                <b>Adresse :</b>
                <span v-if="contact?.SITUATION?.ADRESSE">
                  {{ contact?.SITUATION?.ADRESSE + ' ' }}
                </span>
                <span v-if="contact?.ADRESSE2">
                  {{ contact?.ADRESSE2 + ' ' }}
                </span>
              </div>
              <div class="col-md-6 mb-2">
                <b>Téléphone 1 :</b> {{ contact?.TELEPHONE }}
              </div>
              <div class="col-md-6 mb-2">
                <b>Téléphone 2 :</b> {{ contact?.TELEPHONE2 }}
              </div>
              <div class="col-md-6 mb-2">
                <b>Email :</b> {{ contact?.EMAIL }}
              </div>
              <div class="col-md-6 mb-2">
                <b>Société :</b> {{ contact?.SOCIETE }}
              </div>
              <div class="col-md-6 mb-2">
                <b>Code NAF :</b> {{ contact?.CODENAF }}
              </div>
              <div class="col-md-6 mb-2">
                <b>Siret :</b> {{ contact?.SIRET }}
              </div>
              <div class="col-md-6 mb-2">
                <b>Num TVA :</b> {{ contact?.NUMTVA }}
              </div>



            </div>
          </div>
          <div v-else class="card-body">
            <datanotfound :text="$t(`content.message.datanotfound`)" />
          </div>
        </div>
      </div>
    </div>
    <!-- <ButtonDates /> -->
    <!-- <CardSearch v-if="currentSite" @search="callFunctions" :operation="true" :etat="true" :caisse="true" :vendeur="true" />-->
    <div class="row">

      <div class="col-md-12 grid-margin stretch-card">
        <div class="card ">
          <div class="card-header">
            <h3 class="card-title text-left">
              Plage de dates
            </h3>

          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 text-start">
                <div class="w-50 p-1">
                  <label for="Date" class="form-label">Dates</label>
                  <VueDatePicker locale="fr" @update:model-value="handleDate" v-model="selectedDates"
                    :action-row="{ showSelect: false, showCancel: false }" range multiCalendars :enableTimePicker="true"
                    format="dd-MM-yyyy HH:mm" close-on-scroll auto-apply show-now-button />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-if="selectedTicketsIDs?.length > 0" class="row">
      <div class="col-md-12 text-end">
        <button @click="Get_DetailsTickets" type="submit" class="btn btn-warning mb-3" data-bs-toggle="modal"
          data-bs-target="#generatePDF">
          <i class="far fa-file-pdf"></i> Générer un fichier PDF </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card ">
           <SectionLoaderView v-if="loadingRequest?.getTicketsByCompte " />
          <div class="card-header">
            <h3 class="card-title text-left">
              <div class="noble-ui-logo d-block mb-2">
                Liste<span>&nbsp;des tickets</span>
              </div>
            </h3>

          </div>
          <div v-if="ticketsMongoDB.length > 0" class="card-body">

            <div class="row mb-3 mt-3">
              <div class="col-md-10">
                <span> Showing <b>{{ pagination.data.showing_from }}</b> to <b>{{ pagination.data.showing_to
                    }}</b> of
                  <b>{{ pagination.data.total_items }}</b> entries</span>

                <p><b>page</b> : {{ pagination.data.current_page }} </p>
              </div>
              <div class="col-md-2">
                <select v-model="pagination.per_page" @change="fetchTickets()"
                  class="form-select btn-outline-primary dropdown-toggle">
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="300">300</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
            <div class="table-responsive tableClassss">
              <DataTable showGridlines stripedRows :value="ticketsMongoDB" :selection="selectedTicketsIDs"
                @update:selection="onRowSelectChange" dataKey="id">
                <!-- 'Select All' Checkbox in Header -->
                <Column headerStyle="width: 3rem">
                  <template #header>
                    <input class="cursor-pointer" type="checkbox" v-model="isChecked" @change="toggleSelectAll" />
                  </template>
                  <template #body="slotProps">
                    <input class="cursor-pointer" type="checkbox" v-model="selectedTicketsIDs"
                      :value="slotProps.data?._id" />
                  </template>
                </Column>
                <Column field="NumTicket" header="N° ticket" sortable>
                  <template #body="slotProps">
                    <span v-if="slotProps.data?.NumTicket != '0'">
                      Ticket N°{{ slotProps.data?.NumTicket }}
                    </span>
                  </template>
                </Column>
                <Column field="TableNumber" header="N° Table" sortable>
                  <template #body="slotProps">
                    <span v-if="slotProps.data?.TableNumber">
                      Table N°{{ slotProps.data?.TableNumber }}
                    </span>
                  </template>
                </Column>

                <Column field="LibVendeur" header="Vendeur" sortable>
                  <template #body="slotProps">
                    {{ slotProps.data.LibVendeur }}
                  </template>
                </Column>

                <Column field="Horodatage" header="Date" sortable>
                  <template #body="slotProps">
                    {{ $dayjs(slotProps.data?.Horodatage)?.utc().format("DD/MM/YYYY HH:mm:ss") }}

                  </template>
                </Column>

                <Column field="MontantTTC" header="Montant TTC" sortable>
                  <template #body="slotProps">
                    {{ slotProps.data.MontantTTC.toFixed(2) }} €
                  </template>
                </Column>
                <Column field="Operation" header="Opération">
                  <template #body="slotProps">
                    <span class="table-tag" :style="{ backgroundColor: operationColors[0][slotProps.data.Operation] }">
                      {{ slotProps.data.Operation }}
                    </span>

                  </template>
                </Column>
                <Column field="_id" header="Détails">
                  <template #body="slotProps">
                    <button class="btn btn-info btn-icon-text mx-2 mb-3 mb-md-0"
                      @click="VoirDetailsTicket(slotProps.data?._id)">
                      Voir
                    </button>

                  </template>
                </Column>
              </DataTable>
            </div>
            <Paginationcomt :pagination="pagination.data" @paginate="fetchTickets()" :offset="4">
            </Paginationcomt>
          </div>
          <div v-else class="card-body">
            <datanotfound :text="$t(`content.message.datanotfound`)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { exportMultiTicketsToPdf, exportMultiTicketsToMail } from "@/Utils/generatedTickets.js";
import { DateTime } from "luxon";

export default {
  components: { VueDatePicker },

  mounted() {
    const get = async () => {
      try {

        this.fetchContacts()
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          console.log(error);
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();

    if (this.datePicker.date.range?.length > 0) {
      // this.selectedDates = this.datePicker.date.range
      this.filterData("today", true)
    }

    this.listDestinataires = []
    if (this.User?.email || this.User?.email !== "")
      this.listDestinataires.push({ Email: this.User?.email, Name: this.exportTo?.data?.LibSite });
  },
  data() {
    return {
      contacts: [],
      show: false,
      isModalOpen: false,
      search: {
        Nom: "",
        Titre: null,
        SITE_ID: null,
      },




      operationColors: [
        {
          'VENTE': '',
          'TRANSFERT': '#0be80b',
          'TRANSFERT-TABLE': '#0be80b',
          'ANNULATION': '#bbb',
          'ANNULLER': '#fb6060',
          'JUSTIFICATIF': 'rgb(201, 186, 255)',
          'INTERNE': 'rgb(255, 182, 89)',
        }
      ],
      tickets_mongoDB: [],
      pagination: {
        page: 0,
        limit: 4,
        skip: 0,
        per_page: 50,
        data: [],
      },
      sort: {
        MontantTTC: {
          sortOrder: 1,
        },
        Horodatage: {
          sortOrder: 1,
        }
      },
      ticketSortBy: {
        Horodatage: -1
      },
      selectedTicketsIDs: [], // Stores the selected rows
      isChecked: false,  // Represents the state of the "Select All" checkbox




      exportTo: {
        tickets: [],
        site: null
      },
      ticket_mongoDB: [],
      type: null,
      listDestinataires: [], // Array to store input data
      isMail: false,
      selectedDates: []
    };
  },
  watch: {
    'datePicker.date.range'(newDate, oldDate) {
      this.callFunctions(this.datePicker.date.from, this.datePicker.date.to, this.datePicker.defaultDates.type);
    }
  },
  methods: {
    ...mapActions([
      "searchContacts",
      "getTicketsByCompte",
      "getTicketsByVendeurDates",
      "getDetailsTickets",
      "getTransfertArticlesByGUUID",
      "sendPdfTicketsOnMail"
    ]),
    toggleDetails(contactId) {
      const contact = this.contacts.find(contact => contact?._id === contactId);
      if (contact) {
        contact.showDetails = !contact.showDetails;
      }
    },
    async fetchContacts() {
      const searchData = {}
      if (this.$route.params.id)
        searchData['id'] = this.$route.params.id


      this.contacts = await this.searchContacts(searchData);

      await this.fetchTickets()
    },
    ShowHide(index, iconshow, iconhide, libelle) {
      var ele_arr_down = document.getElementById(iconshow + index);
      var ele_arr_right = document.getElementById(iconhide + index);
      var ele_sub_table = document.getElementById(libelle + index);
      ele_sub_table.classList.toggle("show");
      ele_arr_down.classList.toggle("hide");
      ele_arr_right.classList.toggle("hide");
    },


    async fetchTickets() {
      let from = this.datePicker.date.from
      window.scrollTo(0, 0);
      let current_page = this.pagination?.data?.current_page
        ? this.pagination.data.current_page
        : 1;

      let results = await this.getTicketsByCompte({
        url: "entetes/tickets-compte",
        page: current_page,
        skip: this.pagination.skip,
        limit: this.pagination.per_page,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        AccountHasTicketsGUIDs: this.contacts[0]?.AccountHasTicketsGUIDs,
        CONTACT_ID: this.contacts[0]?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: this.globalSearch?.SelectedOperations?.length ? this.globalSearch?.SelectedOperations : null, LibKey: 'Operation' },
          { LibValeur: this.globalSearch?.SelectedEtats?.length ? this.globalSearch?.SelectedEtats : null, LibKey: 'Etat' },
        ],
        WhereLibNotInArray: [
          { LibValeur: this.globalSearch?.SelectedEtats?.length == 0 ? 'Ouvert' : null, LibKey: 'Etat' },
        ],
        ticketSortBy: this.ticketSortBy
      });
      this.tickets_mongoDB = results.data;
      this.pagination.data = results.pagination;
    },
    VoirDetailsTicket(ticket_id) {
      this.$router.push({ name: "detailsticket", params: { id: ticket_id } }); //
    },

    callFunctions(from, to, type) {
      if (this.currentSite?._id) {
        let range = from
        this.fetchTickets(range, to);
      }
    },
    toggleSelectAll() {
      // Toggle selections based on isChecked
      if (!this.isChecked) {
        this.selectedTicketsIDs = [];
      } else {
        //this.selectedTicketsIDs = [...this.ticketsMongoDB];
        this.selectedTicketsIDs = this.ticketsMongoDB.map(item => item._id);
      }
    },
    onRowSelectChange() {
      // This ensures isChecked reflects correct state in UI
      if (this.selectedTicketsIDs.length !== this.ticketsMongoDB.length) {
        this.isChecked = false;
      }
    },
    // get details selected tickets
    async Get_DetailsTickets() {
      this.exportTo.tickets = []; // Initialize the array to store all tickets' data
      this.TransfertTVA = {}; // Initialize the TransfertTVA object

      // Fetch all tickets at once
      let results = await this.getDetailsTickets({
        url: "entetes/details-tickets",
        ids: this.selectedTicketsIDs,
      });
      this.ticket_mongoDB = results
      results.forEach(async (ticket) => {
        // Initialize arrays for each ticket
        let TransfertTVA = {};
        let arr_commandes = [];
        let arr_statistiques = [];
        let arr_reglements = [];
        let arr_tvas = [];
        let CommandeArticles = [];
        let CommandeTransfert = [];
        let concatCommandeTypes = [];
        let transfertArticles = []; // New array for transfert articles


        // Handle COMMANDE
        if (ticket?.COMMANDE) {
          if (!Array.isArray(ticket.COMMANDE)) {
            arr_commandes.push(ticket.COMMANDE);
          } else {
            arr_commandes.push(...ticket.COMMANDE);
          }
        }

        // Process Commande Articles and Transferts
        arr_commandes?.forEach(commande => {
          if (commande?.ARTICLE) {
            if (!Array.isArray(commande?.ARTICLE)) {
              CommandeArticles.push(commande.ARTICLE);
            } else {
              CommandeArticles.push(...commande.ARTICLE);
            }
          }
          if (commande?.TRANSFERT) {
            if (!Array.isArray(commande?.TRANSFERT)) {
              CommandeTransfert.push(commande.TRANSFERT);
            } else {
              CommandeTransfert.push(...commande.TRANSFERT);
            }
          }
          // if (commande?.ARTICLE) CommandeArticles.push(...commande.ARTICLE);
          // if (commande?.TRANSFERT) CommandeTransfert.push(...commande.TRANSFERT);

        });
        //concatCommandeTypes = [...CommandeArticles, ...CommandeTransfert];
        concatCommandeTypes = CommandeArticles.concat(CommandeTransfert)

        // Handle STATISTIQUES
        if (ticket?.STATISTIQUES) {
          if (!Array.isArray(ticket.STATISTIQUES)) {
            arr_statistiques.push(ticket.STATISTIQUES);
          } else {
            arr_statistiques.push(...ticket.STATISTIQUES);
          }
        }

        // Handle REGLEMENT
        if (ticket?.REGLEMENT) {
          if (!Array.isArray(ticket.REGLEMENT)) {
            arr_reglements.push(ticket.REGLEMENT);
          } else {
            arr_reglements.push(...ticket.REGLEMENT);
          }
        }

        // Handle TVA
        if (ticket?.TVA) {
          if (!Array.isArray(ticket.TVA)) {
            arr_tvas.push(ticket.TVA);
          } else {
            arr_tvas.push(...ticket.TVA);
          }
        }
        // Fetch transfert articles for the current ticket using the ticket's GUID
        /*let arr_transferts = await this.getTransfertArticlesByGUUID({
          url: "entetes/transfert-articles",
          _GUID: ticket.GUID,
        });



        // Initialize transfer values
        let ValeurTTC = 0, ValeurHT = 0, ValeurTVA = 0;

        // Process the transfert articles for TVA calculation
        arr_transferts.forEach(transfert => {
          // Store fetched transfert articles
          transfertArticles.push(...transfert?.ARTICLE);

          transfert?.ARTICLE?.forEach(article => {
            if (article?.TVA) {
              const code = `${article.TVA.Numero} ${article.TVA.Libelle}`;
              if (!TransfertTVA[code]) {
                TransfertTVA[code] = {
                  Libelle: code,
                  ValeurTTC: article.TVA.ValeurTTC,
                  ValeurHT: article.TVA.ValeurHT,
                  ValeurTVA: article.TVA.ValeurTVA,
                };
              } else {
                TransfertTVA[code].ValeurTTC += article.TVA.ValeurTTC;
                TransfertTVA[code].ValeurHT += article.TVA.ValeurHT;
                TransfertTVA[code].ValeurTVA += article.TVA.ValeurTVA;
              }
            }
          });
        });
        if (TransfertTVA.length > 0) {
          // Calculate total TVA for the ticket and store it as 'Totaux'
          Object.values(TransfertTVA).forEach(data => {
            ValeurTTC += data.ValeurTTC;
            ValeurHT += data.ValeurHT;
            ValeurTVA += data.ValeurTVA;
          });

          // TransfertTVA.push({
          //   Libelle: 'Totaux',
          //   ValeurTTC,
          //   ValeurHT,
          //   ValeurTVA,
          // });

          TransfertTVA['Totaux'] = {
            Libelle: 'Totaux',
            ValeurTTC,
            ValeurHT,
            ValeurTVA,
          };
        }*/

        // Store all data for the current ticket in the export array
        this.exportTo.tickets.push({
          data: ticket,
          arr_commandes,
          arr_statistiques,
          arr_reglements,
          arr_tvas,
          CommandeArticles,
          CommandeTransfert,
          concatCommandeTypes,
          //  transfertArticles, // Include transfertArticles in export
          // TransfertTVA: TransfertTVA,
          // totalTransferts: Math.abs(TransfertTVA['Totaux']?.ValeurTTC) || 0,

        });


      });


      this.exportTo.site = this.getSiteAffectedToContact ?? {}
      this.exportTo.CONTACT = this.contacts[0] ?? null


    },

    async generatePdfFile() {
      await exportMultiTicketsToPdf(this.exportTo, this.type, "a4")
      this.closeModal()
    },
    async generateFileToMail() {

      this.type

      const recipients = []
      this.listDestinataires?.forEach((item, index) => {
        if (item?.Email !== '')
          recipients.push(this.listDestinataires[index]);
      });

      if (this.User?.email || this.User?.email !== "") {
        const pdfData = await exportMultiTicketsToMail(this.exportTo, this.type, "a4")

        // Send the email with the uploaded Excel file
        const data = {
          recipients: recipients, //this.User?.email,
          subject: 'LibSite de : ' + this.getSiteAffectedToContact?.LibSite ?? '',
          message: "",

          site: this.getSiteAffectedToContact ?? {},

          pdfData: pdfData, // Pass raw PDF data
        }
        const isSuccess = await this.sendPdfTicketsOnMail(data);
        if (isSuccess == true) {
          this.closeModal()
        }
      } else {
        alertSwal("error", "Votre profile ne continet pas une adresse mail");
      }


    },
    addItem() {
      // Add a new item to the listDestinataires array
      this.listDestinataires.push({ Email: '', Name: this.exportTo?.site?.LibSite || ' Non ' });
    },
    removeItem(index) {
      // Remove the item at the specified index
      this.listDestinataires.splice(index, 1);
    },
    typeChoosen(type) {
      if (type == 'recipients') {
        this.isMail = true
      } else {
        this.isMail = false
        this.type = type
      }

    },
    closeModal() {
      const modalElement = document.getElementById('generatePDF');
      const modal = bootstrap.Modal.getInstance(modalElement) || new bootstrap.Modal(modalElement);

      modal.hide();
      this.initData()
    },
    initData() {
      this.isMail = false
      this.type = null
      this.listDestinataires = []
      this.selectedTicketsIDs = [];
      this.isChecked = false

      this.exportTo = {
        tickets: [],
        site: null
      }

      if (this.User?.email || this.User?.email !== "")
        this.listDestinataires.push({ Email: this.User?.email, Name: this.exportTo?.data?.LibSite });


    },
    handleDate(selectedDate) {
      let dateFrom, dateTo;

      const [fromHour, fromMinutes] = this.parametrages?.calculeTicket?.TimeDebut?.split(':') ?? ['00', '00']
      const [toHour, toMinute] = this.parametrages?.calculeTicket?.TimeFin?.split(':') ?? ['23', '59']


      const from = (this.$dayjs(selectedDate[0]).format('YYYY-MM-DD HH:mm'))
      dateFrom = DateTime.fromFormat(from, 'yyyy-MM-dd HH:mm');
      const to = (this.$dayjs(selectedDate[1]).format('YYYY-MM-DD HH:mm'))
      dateTo = DateTime.fromFormat(to, 'yyyy-MM-dd HH:mm');


      if (fromHour > 0) {
        dateTo = dateTo.plus({ day: 1 })
      }
      this.datePicker.date.from = dateFrom.toFormat('MM-dd-yyyy HH:mm');
      this.datePicker.date.to = dateTo.toFormat('MM-dd-yyyy HH:mm');



      const isodateFrom = DateTime.fromISO(dateFrom);
      const isodateTo = DateTime.fromISO(dateTo);
      const formattedDateFrom = isodateFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
      const formattedDateTo = isodateTo.toISO({ includeOffset: false, suppressMilliseconds: false });
      this.datePicker.date.range = [formattedDateFrom, formattedDateTo];


      this.datePicker.defaultDates.type = null
    },
    filterData(option, selectOption) {
      const timeZone = 'Europe/Paris';
      const currentDate = DateTime.now().setZone(timeZone).setZone('UTC+0');
      let dateFrom, dateTo;

      const [fromHour, fromMinutes] = this.parametrages?.calculeTicket?.TimeDebut?.split(':') ?? ['00', '00']
      const [toHour, toMinute] = this.parametrages?.calculeTicket?.TimeFin?.split(':') ?? ['23', '59']

      const startTime = { hour: fromHour, minute: fromMinutes }
      const endTime = { hour: toHour, minute: toMinute }

      dateFrom = currentDate.set(startTime);
      dateTo = dateFrom.set(endTime);

      if (fromHour > 0) {
        dateTo = dateTo.plus({ days: 1 })
      }

      const isodateFrom = DateTime.fromISO(dateFrom);
      const isodateTo = DateTime.fromISO(dateTo);
      const formattedDateFrom = isodateFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
      const formattedDateTo = isodateTo.toISO({ includeOffset: false, suppressMilliseconds: false });

      if (selectOption) {
        this.datePicker.date.range = [formattedDateFrom, formattedDateTo];
        this.datePicker.date.from = dateFrom.toFormat('MM-dd-yyyy HH:mm');
        this.datePicker.date.to = dateTo.toFormat('MM-dd-yyyy HH:mm');
        this.selectedDates = [this.datePicker.date.from, this.datePicker.date.to];
        this.datePicker.defaultDates.type = option
      }

    },
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase", currentSite: "currentSite", }),

    ...mapState(["ticket_id", "datePicker", "globalSearch", "loadingRequest"]),
    ticketsMongoDB() {
      return this.tickets_mongoDB ? this.tickets_mongoDB : [];
    },
    getSiteAffectedToContact() {

      if (this.contacts?.length > 0) {

        return this.$store.state.managerWithStores?.associatedRestaurants
          .filter(site => {
            return site?._id?.includes(this.contacts[0]?.SITE_ID?.toString());
          })
          .reduce((acc, site) => {
            acc = site; // Use the site's ID as the key
            return acc;
          }, {});
      }

    }

  },
};
</script>
<!-- eslint-disable -->
<style>
.abn-disabled {
  background-color: #f69a9a;
}

.hide {
  display: none;
}
</style>
