<!-- eslint-disable -->
<script setup>
import statistiquesExcel from "@/components/ToPDF/Statistiques/excel.vue";
import ventesParVendeurPDF from "@/components/ToPDF/ventesParVendeur/IndexPdf.vue";
import CardSearch from "@/components/CardSearch.vue";
</script>
<!-- eslint-disable -->
<template>
  <div class="row mb-4">
    <div class="col-md-4 text-end float-end">
      <statistiquesExcel :exportTo="exportTo" />
    </div>
  </div>
  <CardSearch v-if="currentSite" @search="callFunctions" :operation="false" :etat="false" :caisse="true"
    :vendeur="true" />

  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
         <SectionLoaderView v-if="loadingRequest?.BoardMeilleursVents" />
        <div class="card-header">
          <h3 class="card-title text-left">
            Ventes par vendeur
            <div v-if="data.ventes_par_vendeur.length > 0" class="float-end">
              <ventesParVendeurPDF :exportTo="exportTo.ventes_par_vendeur" :vendeur="searchs.vendeurs"
                :dateFrom="datePicker.date.from" :dateTo="datePicker.date.to" />
            </div>
          </h3>
        </div>
        <div v-if="data.ventes_par_vendeur.length > 0" class="card-body">
          <div class="table-responsive  ">

            <DataTable showGridlines stripedRows :value="data.ventes_par_vendeur">
              <Column field="LibVendeur" header="Vendeur" sortable></Column>
              <Column field="Libelle" header="Article" sortable> </Column>
              <Column field="QteTotal" header="Quantité" sortable> </Column>
              <Column field="TotalTTC" header="Montant (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalTTC?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>

</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { VueDatePicker, ventesParVendeurPDF },
  watch: {
    'datePicker.date.range'(newDate, oldDate) {

      this.callFunctions();
    },
  },
  data() {
    return {
      exportTo: {
        vendeurs: {
          columns: [],
          data: [],
          fileName: "",
        },
        ventes_par_vendeur: {
          columns: [],
          data: [],
          fileName: "",
        },
      },
      data: {
        statistiques: [],
        ventes_par_vendeur: [],
      },
      pagination: {
        page: 0,
        limit: 4,
        skip: 0,
        per_page: 10,
        data: [],
      },
      searchs: {
        vendeurs: ["vendeur"],
      },
      interval: null,
    };
  },

  methods: {

    ...mapMutations(["setTicketID"]),
    ...mapActions([

      "BoardMeilleursVents",
    ]),


    async getBoardArticlesVenduParVendeur(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0; // 0 to get all rows
      this.data.ventes_par_vendeur = await this.BoardMeilleursVents({
        url: "board/meilleursvents",
        page: page,
        limit: limit,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
      this.exportTo.ventes_par_vendeur.data = [];
      this.exportTo.ventes_par_vendeur.data = this.data.ventes_par_vendeur;
      let file_vendeur = "";
      if (
        this.searchs.vendeurs.length == 1 &&
        this.searchs.vendeurs[0] != "vendeur"
      ) {
        file_vendeur = "-" + this.searchs.vendeurs[0];
      }
      this.exportTo.ventes_par_vendeur.fileName =
        "ventes-par-vendeur" + file_vendeur + "_" + from + "_" + to;
      if (this.data.ventes_par_vendeur.length > 0) {
        this.exportTo.ventes_par_vendeur.columns = [];
        if (file_vendeur == "") {
          this.exportTo.ventes_par_vendeur.columns.push("Vendeur");
        }
        this.exportTo.ventes_par_vendeur.columns.push("Article");
        this.exportTo.ventes_par_vendeur.columns.push("Quantité");
        this.exportTo.ventes_par_vendeur.columns.push("Montant");
      }
    },


    callFunctions() {
      if (this.currentSite?._id) {
        let range = this.datePicker.defaultDates.type ? this.datePicker.defaultDates.type : this.datePicker.date.from
        this.getBoardArticlesVenduParVendeur(range, this.datePicker.date.to);
      }
    },
    ShowHide(index, iconshow, iconhide, libelle) {
      var ele_arr_down = document.getElementById(iconshow + index);
      var ele_arr_right = document.getElementById(iconhide + index);
      var ele_sub_table = document.getElementById(libelle + index);
      ele_sub_table.classList.toggle("show");
      ele_arr_down.classList.toggle("hide");
      ele_arr_right.classList.toggle("hide");
    },

    priceFormat(value) {
      return "€ " + value;
    },

  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
    ...mapState(["ticket_id", "datePicker", "globalSearch", "loadingRequest"]),
  },
};
</script>
<!-- eslint-disable -->
<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}
</style>
